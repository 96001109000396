import React, { useEffect, useState } from 'react'
import { BlobProvider } from '@react-pdf/renderer'
import { CFormSelect } from '@coreui/react'
import Spread from './PDF/Spread'
import axios from 'axios'
import $ from 'jquery'

//services
import { getAgents } from 'src/services'

function AgentReport() {
  const [selectedOption, setSelectedOption] = useState('')
  const [agentList, setAgentList] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [mapData, setMapData] = useState({})

  useEffect(() => {
    getAgents()
      .then((res) => {
        if (res.data.status === 200) {
          setAgentList(res.data.agent)
        }
        setTimeout(() => {
          $('#dataTable').DataTable({
            scrollX: false,
            pageLength: 100,
          })
        }, 10)
        setLoading(false)
      })
      .catch(() => {
        setTimeout(() => {
          $('#dataTable').DataTable({
            scrollX: false,
            pageLength: 100,
          })
        }, 10)
        setLoading(false)
      })
  }, [])

  const handleInput = (e) => {
    setSelectedOption(e.target.value)
  }

  const getDataReport = () => {
    setLoading(true)
    if (selectedOption) {
      axios
        .post(`/api/agent_report`, { option: selectedOption })
        .then((res) => {
          if (res.data.agent_report.length === 0) {
            setError('Data not found')
            setLoading(false)
          } else {
            //console.log('agent report', res.data)
            setMapData(res.data.agent_report)
            setError('')
            setLoading(false)
          }
        })
        .catch(() => {
          setError('Error fetching data')
          setLoading(false)
        })
    } else {
      setError('Please select an option')
      setLoading(false)
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return date.toLocaleDateString('en-US', options)
  }

  return (
    <>
      <h1 className="mb-4">Agent Report</h1>
      <div className="d-flex align-items-end">
        <div className="d-flex flex-column">
          <CFormSelect
            onChange={handleInput}
            aria-label="Default select example"
            name="agent_select"
            value={selectedOption}
          >
            <option>-- Please select --</option>
            {agentList.map((agent) => (
              <option key={agent.id} value={agent.id}>
                {agent.name}
              </option>
            ))}
          </CFormSelect>
        </div>
        <div className="mx-3">
          <button className="px-3 btn btn-block btn-outline-info btn-sm" onClick={getDataReport}>
            Search
          </button>
        </div>
      </div>
      <div className="mt-3">
        {error && <div className="btn btn-block btn-outline-info btn-sm">{error}</div>}
        {loading && <div className="btn btn-block btn-outline-info btn-sm mx-2">Loading....</div>}

        {!loading && !error && mapData.length > 0 ? (
          <>
            <div className="content">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">{mapData[0].agent.name} Order List</h5>
                </div>
                <div className="card-body">
                  <table id="dataTable" className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th className="small">Date Created</th>
                        <th className="small">Order Number</th>
                        <th className="small">Invoice Number</th>
                        <th className="small">Customer</th>
                        <th className="small">Products</th>
                        <th className="small">Tonnes Actual</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mapData.map((ao, i) => (
                        <tr key={i}>
                          <td>{formatDate(ao.created_at)}</td>
                          <td>{ao.id}</td>
                          <td>{ao.id}</td>
                          <td>{ao.customer.account_name}</td>
                          <td>
                            {ao.order_list[0].order_list_products.map((pro, j) => (
                              <span key={j}>{pro.product_name}</span>
                            ))}
                          </td>
                          <td>{ao.tons_actual}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          !loading &&
          !error && <div className="btn btn-block btn-outline-info btn-sm">Data not found</div>
        )}
      </div>
    </>
  )
}

export default AgentReport
