import React from 'react'
import format from 'date-fns/format'

const DataTableItem = ({ data, columns, renderRowActions }) => {
  const renderCellContent = (item, col) => {
    // Handle special cases based on the column key
    switch (col.key) {
      case 'product_mix_code':
        // Special handling for 'product_mix_code'
        return item[col.key].split(' \n ').map((line, lineIndex) => {
          const parts = line.split(', ')
          return (
            <div key={lineIndex}>
              {parts.map((part, partIndex) => (
                <span key={partIndex}>
                  <strong>{part.split(':')[0]}:</strong> {part.split(':')[1]}
                  {partIndex < parts.length - 1 ? ', ' : ''}
                </span>
              ))}
            </div>
          )
        })

      case 'order_id':
        // Example of handling for 'code'
        return '#' + item.id || 'N/A'

      case 'code':
        // Example of handling for 'code'
        return item.code ? item.code[col.key] || 'N/A' : 'N/A'

      case 'customer_reference':
        // Example of handling for 'customer_reference'
        return item[col.key] || 'N/A'

      case 'account_name':
        // Example of handling for 'account_name'
        //console.log(item.customer?.customer_phone[0]?.phone_number)
        return (
          <div>
            <div className="small fw-bold">{item.customer ? item.customer[col.key] : 'N/A'}</div>
            <br></br>
            <div>
              {item.customer.customer_phone.map((phone, index) => (
                <div key={index}>
                  <p className="small">
                    <span className="fw-bold"> {phone.phone_type} : </span>
                    {phone.phone_number}
                  </p>
                  <br></br>
                </div>
              ))}
            </div>
          </div>
        )

      case 'address_contact':
        // Example of handling for 'account_name'
        return (
          <div>
            <div>
              {item.customer.customer_address.map((address, index) => (
                <div key={index}>
                  <p className="small">
                    <span className="fw-bold">RESIDENT NAME : </span>
                    {address.resident_name}
                  </p>
                  <br></br>
                  <p className="small">
                    <span className="fw-bold">RESIDENT CONTACT : </span>
                    {address.resident_contact}
                  </p>
                  <br></br>
                  <p className="small">
                    <span className="fw-bold">RESIDENT CONTACT TWO : </span>
                    {address.resident_contact_two}
                  </p>
                  <br></br>
                </div>
              ))}
            </div>
          </div>
        )

      case 'docket_number':
        // Example of handling for 'account_name'
        //console.log(item.order_list)
        return (
          <div>
            <span className="small">
              {item.order_list.map((list, i) =>
                list.docket_numbers.slice(0, 5).map((docket, i) => (
                  <p key={i}>
                    <span className="fw-bold">DN:</span> {docket.docket_number}
                  </p>
                )),
              )}
            </span>
          </div>
        )

      case 'products':
        // Example of handling for 'account_name'
        //console.log(item.order_list)
        return (
          <div>
            <span className="small">
              {item.order_list?.map((product_code, i) => (
                <div key={i}>
                  {product_code.order_list_products?.map((code, ind) => (
                    <span className="badge bg-dark mx-1" key={ind}>
                      {code.product_name}
                      {ind === product_code.order_list_products.length - 1 ? '' : ''}
                    </span>
                  ))}
                </div>
              ))}
            </span>
          </div>
        )

      case 'delivery_location':
        // Example of handling for 'account_name'
        //console.log(item.order_list)
        return (
          <div>
            <span className="small">{item.orderdeliveryzone?.name}</span>
          </div>
        )

      case 'machine':
        // Example of handling for 'account_name'
        //console.log(item.order_list)
        return (
          <div>
            <p className="small">
              <span className="fw-bold">
                {item.machine_id === null ? 'N/A' : item.machine.name}
              </span>
              {item.operator?.map((data, i) => (
                <p key={i} className="p-0 m-0">
                  {data.operator?.fname} {data.operator?.lname}
                </p>
              ))}
            </p>
          </div>
        )

      case 'status':
        // Example of handling for 'account_name'
        //console.log(item.order_list)
        return (
          <div>
            <span className="small">
              <span className="badge bg-primary mx-1">
                {item.order_status === '0' && 'In Progress'}
              </span>
              <span className="badge bg-success mx-1">
                {item.order_status === '1' && 'Completed'}
              </span>
            </span>
          </div>
        )

      case 'last_modified':
        // Example of handling for 'account_name'
        //console.log(item.order_list)
        return (
          <div>
            <p className="small">{format(new Date(item.updated_at), 'dd/MM/yyyy | HH:mm:ss')}</p>
          </div>
        )

      // Add other cases as needed

      default:
        // Default case for keys that don't require special handling
        return item[col.key]
    }
  }

  return (
    <table id="dataTable" className="table table-bordered table-striped">
      <thead>
        <tr>
          <th>#</th>
          {columns.map((col, index) => (
            <th className="small fw-bold" key={index}>
              {col.label}
            </th>
          ))}
          <th className="small">Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={item.id}>
            <td>{index + 1}</td>
            {columns.map((col, colIndex) => (
              <td key={`${item.id}-${colIndex}`}>{renderCellContent(item, col)}</td>
            ))}
            <td>{renderRowActions(item)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default DataTableItem
