import React, { useContext, useState, useEffect } from 'react'
import { useHistory, NavLink, Link } from 'react-router-dom'
import swal from 'sweetalert2'
import { CFormCheck } from '@coreui/react'
import Preloader from '../preloader/Preloader'
import { Usercntxt } from '../../context/UserContext'
import { BlobProvider } from '@react-pdf/renderer'
import MyDocument from './PDF/SingleOrder'
import $ from 'jquery'

//components
import { DataTableItem, UserRoute, BreadCrumb, Card } from 'src/components'

//services
import { getOrders, deleteOrders } from 'src/services'

function Order() {
  const history = useHistory()
  const user = useContext(Usercntxt)
  const [generateReport, setgenerateReport] = useState(false)
  const [loading, setLoading] = useState(true)
  const [checklist, setCheckList] = useState(2)
  const [inputList, setInputList] = useState([])

  useEffect(() => {
    getOrders()
      .then((res) => {
        if (res.data.status === 200) {
          setInputList(res.data.invoice)
        }
        setTimeout(() => {
          $('#dataTable').DataTable({
            scrollX: true,
            pageLength: 100,
          })
        }, 10)
        setLoading(false)
      })
      .catch(() => {
        setTimeout(() => {
          $('#dataTable').DataTable({
            scrollX: false,
            pageLength: 100,
          })
        }, 10)
        setLoading(false)
      })
  }, [])

  const initializeDataTable = () => {
    setTimeout(() => {
      $('#dataTable').DataTable({
        scrollX: true,
        pageLength: 100,
        // Add any additional DataTable configuration here
      })
    }, 10)
  }

  //handle chages for checkboxes
  const handleRadio = (e) => {
    const selectedValue = Number(e.target.value)
    setCheckList(selectedValue)

    setLoading(true)
    getOrders(selectedValue)
      .then((res) => {
        //console.log('API response for value', selectedValue, ':', res)
        if (res.data.status === 200) {
          switch (selectedValue) {
            case 2:
              setInputList(res.data.invoice)
              break
            case 1:
              setInputList(res.data.invoice_completed)
              break
            case 0:
              //console.log('Case 0 Data:', res.data.invoice_progress)
              setInputList(res.data.invoice_progress)
              break
            default:
            //console.log('Unhandled case')
          }
        }
        initializeDataTable()
      })
      .catch((error) => {
        console.error('API Error:', error)
        initializeDataTable()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const radioButtons = (
    <div className="form-group d-flex d-inline mb-3">
      <CFormCheck
        onChange={(e) => handleRadio(e)}
        type="radio"
        name="status_filter"
        id="flexRadioDefault2"
        label="All"
        value={2}
        className="fs-5"
        checked={checklist === 2}
      />
      <CFormCheck
        onChange={(e) => handleRadio(e)}
        type="radio"
        name="status_filter"
        id="flexRadioDefault1"
        label="Complete"
        value={1}
        checked={checklist === 1}
        className="fs-5 ms-3"
      />
      <CFormCheck
        onChange={(e) => handleRadio(e)}
        type="radio"
        name="status_filter"
        id="flexRadioDefault0"
        label="Open"
        value={0}
        checked={checklist === 0}
        className="fs-5 ms-3"
      />
    </div>
  )

  const deleteRecord = (e, id) => {
    e.preventDefault()

    const thisClicked = e.currentTarget

    swal
      .fire({
        title: 'Are you sure you want to proceed?',
        text: 'This will completely delete the Operators permanently',
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'Cancel!',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Proceed!',
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteOrders(id)
            .then((res) => {
              if (res.data.status === 200) {
                swal.fire('Deleted!', res.data.message, 'success')
                thisClicked.closest('tr').remove()
              } else if (res.data.status === 404) {
                swal.fire('Error', res.data.message, 'error')
              }
            })
            .catch(() => {
              initializeDataTable()
              setLoading(false)
            })
        } else if (result.isDenied) {
          swal.fire('Changes are not saved', '', 'info')
        }
      })
  }

  const renderRowActions = (item) => {
    return (
      <>
        <div className="d-flex">
          {user.can('access orders') && (
            <Link
              to={`/orders/update/${item.id}`}
              className="btn btn-block btn-outline-info btn-sm"
            >
              <i className="far fa-edit"></i> Edit
            </Link>
          )}
          &nbsp;
          {user.can('access orders') && (
            <button
              type="button"
              onClick={(e) => deleteRecord(e, item.id)}
              className="btn btn-block btn-outline-danger btn-sm"
            >
              Delete
            </button>
          )}
          &nbsp;
        </div>
        <br />
        {item.order_status === '1' && !generateReport && (
          <button className="btn btn-outline-info btn-sm" onClick={() => setgenerateReport(true)}>
            Generate Report
          </button>
        )}

        {generateReport && (
          <BlobProvider document={<MyDocument item={item} />}>
            {({ url, loading, error }) =>
              !loading ? (
                <a
                  className="btn btn-block btn-outline-primary btn-sm"
                  href={url}
                  rel="noreferrer"
                  target="_blank"
                >
                  Download
                </a>
              ) : (
                <a
                  className="btn btn-block btn-outline-info btn-sm"
                  href={url}
                  rel="noreferrer"
                  target="_blank"
                >
                  Loading Report
                </a>
              )
            }
          </BlobProvider>
        )}
      </>
    )
  }

  if (loading) {
    return <Preloader />
  }

  const columns = [
    { label: 'Order', key: 'order_id' },
    { label: 'Code', key: 'code' },
    {
      label: 'Customer Reference',
      key: 'customer_reference',
    },
    { label: 'Account Contact', key: 'account_name' },
    { label: 'Address Contact', key: 'address_contact' },
    { label: 'Docket Numbers', key: 'docket_number' },
    { label: 'Product(s)', key: 'products' },
    { label: 'Delivery Location', key: 'delivery_location' },
    { label: 'Machine', key: 'machine' },
    { label: 'Status', key: 'status' },
    { label: 'Last Modified', key: 'last_modified' },
    // ... other columns
  ]

  const title = 'Orders'

  const breadcrumb = [
    {
      title: title,
      name: 'Add Orders',
      subtitle: '',
      subtitleroute: '',
    },
  ]

  const buttonValues = [
    {
      title: 'Add Orders',
      variants: 'btn btn-primary my-3 mr-2',
      routes: '/orders/create',
    },
  ]

  const cardValues = [
    {
      title: title,
    },
  ]

  return (
    <div>
      <BreadCrumb data={breadcrumb} />
      <UserRoute data={buttonValues} />

      <Card data={cardValues} ifOrder={true}>
        {radioButtons}
        {<DataTableItem data={inputList} columns={columns} renderRowActions={renderRowActions} />}
      </Card>
    </div>
  )
}

export default Order
