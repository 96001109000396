import React, { useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import axios from 'axios'
import Preloader from '../preloader/Preloader'
import swal from 'sweetalert2'
import $ from 'jquery'
import 'datatables.net'
import { CSVLink } from 'react-csv' // For CSV download

function Customers() {
  const [loading, setLoading] = useState(true)
  const [customerList, setCustomerList] = useState([])
  const [xeroCustomerList, setXeroCustomerList] = useState([])
  const [addressData, setAddressData] = useState([])

  useEffect(() => {
    axios.get(`/api/show_customers`).then((res) => {
      if (res.data.status === 200) {
        setCustomerList(res.data.customer)

        const filteredCustomers = res.data.xeroContact.body.Contacts.filter(
          (contact) => contact.IsCustomer === true,
        ).slice(0, 1500)

        setXeroCustomerList(filteredCustomers)
        //console.log('Xero Customer List:', filteredCustomers)
      }

      setTimeout(() => {
        $('#dataTable').DataTable({
          scrollX: false,
          pageLength: 100,
        })
      }, 10)

      setLoading(false)
    })

    // Fetch address data
    axios.get(`/api/show_address`).then((res) => {
      if (res.data.status === 200) {
        // Transform data to include all other details as a string
        const transformedData = res.data.address.map((item) => ({
          id: item.id || '',
          customer_address_id: item.customer_address_id || '',
          address: item.address || '',
          del_zone: item.del_zone || '',
          delivery_zone_id: item.delivery_zones?.id || '',
          delivery_zone_name: item.delivery_zones?.name || '',
          location_note: item.location_note || '',
          p_location: item.p_location || '',
          resident_contact: item.resident_contact || '',
          resident_contact_two: item.resident_contact_two || '',
          resident_name: item.resident_name || '',
          resident_name_two: item.resident_name_two || '',
          created_at: item.created_at || '',
          updated_at: item.updated_at || '',
        }))

        setAddressData(transformedData)
        //console.log('Transformed Address Data:', transformedData)
      } else {
        console.error('Error fetching show_address:', res.data.message)
      }
    })
  }, [])

  const headers = [
    { label: 'ID', key: 'id' },
    { label: 'Customer Address ID', key: 'customer_address_id' },
    { label: 'Address', key: 'address' },
    { label: 'Delivery Zone', key: 'del_zone' },
    { label: 'Delivery Zone ID', key: 'delivery_zone_id' },
    { label: 'Delivery Zone Name', key: 'delivery_zone_name' },
    { label: 'Location Note', key: 'location_note' },
    { label: 'Primary Location', key: 'p_location' },
    { label: 'Resident Contact', key: 'resident_contact' },
    { label: 'Resident Contact Two', key: 'resident_contact_two' },
    { label: 'Resident Name', key: 'resident_name' },
    { label: 'Resident Name Two', key: 'resident_name_two' },
    { label: 'Created At', key: 'created_at' },
    { label: 'Updated At', key: 'updated_at' },
  ]

  if (loading) {
    return <Preloader />
  } else {
    const viewProduct_HTMLTable_Xero = xeroCustomerList.map((item, i) => {
      const matchingCustomer = customerList.find(
        (customer) => customer.contact_id === item.ContactID,
      )
      console.log(matchingCustomer?.customer_address)
      console.log(
        'Contact id',
        item.ContactID,
        'Customer contact id',
        matchingCustomer?.customer_address.contact_id,
      )

      return (
        <tr key={item.ContactID}>
          <td>{i + 1}</td>
          <td>{item.Name}</td>
          <td>{item.FirstName}</td>
          <td>{item.CompanyNumber}</td>
          <td>
            {item.Phones.map((phone, index) => (
              <span key={index}>{phone.PhoneNumber} </span>
            ))}
          </td>
          <td>
            {matchingCustomer?.customer_address
              .filter((address) => address.p_location === item.ContactID)
              .map((address, index) => (
                <div key={index}>
                  <span className="fw-bold">Address: </span> {address?.address}
                  <br />
                </div>
              ))}
          </td>
          <td>
            <Link
              to={`/customers/update/${item.ContactID}`}
              className="btn btn-block btn-outline-info btn-sm me-2 mb-2"
            >
              <i className="far fa-edit"></i> Edit
            </Link>
          </td>
        </tr>
      )
    })

    return (
      <div className="content-wrapper">
        <nav aria-label="breadcrumb p-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Customers
            </li>
          </ol>
        </nav>

        <h3 className="text-dark">All Customers</h3>

        <div className="content">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">All Customers</h5>
              {/* CSV Download Button */}
              {/* <CSVLink
                data={addressData}
                headers={headers}
                filename="show_address_data.csv"
                className="btn btn-success"
                style={{ float: 'right' }}
              >
                Download Address Data
              </CSVLink> */}
            </div>
            <div className="card-body">
              <table id="dataTable" className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Account Name</th>
                    <th>Account Contact Name</th>
                    <th>Code</th>
                    <th>Phone</th>
                    <th>Address</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{viewProduct_HTMLTable_Xero}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Customers
