const HandleCalculateTonsActual = (data) => {
  let tot = 0
  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < data[i].docket_numbers.length; j++) {
      tot += parseFloat(data[i].docket_numbers[j].weight)
    }
  }

  return tot
}

const xeroCodeGenerator = (
  sku_location,
  order_type,
  machines,
  product_name,
  spread_charge_rate,
  spr_id,
  quantity_type,
  quantity_type_id,
  inputList,
) => {
  if (product_name === 'FRE') {
    return 'FRE'
  }

  let od = order_type.find((x) => x.id == inputList.order_type)

  let mc = machines.find((x) => x.id == inputList.machine_id)

  let sku = sku_location.find((x) => x.id == inputList.sku_location)

  let scr = spread_charge_rate.find((x) => x.id == spr_id)

  let qunt = quantity_type.find((x) => x.id == quantity_type_id)

  //console.log('New mix xero code', inputList.product_information[0].order_list_products.length)

  /*  if (inputList.product_information[0].order_list_products.length > 1) {
    return `${mc?.machine_code}MIX.${sku?.xero_code}`
  } */ if (od?.name === 'SPREAD') {
    if (mc?.machine_code && product_name && scr?.xero_code && sku?.xero_code) {
      return `${mc?.machine_code}${product_name}${scr?.xero_code}.${sku?.xero_code}`
    } else {
      return 'N/A'
    }
  } else if (od?.name === 'BULK DUMPED') {
    if (product_name && qunt?.xero_code && sku?.xero_code) {
      return `${product_name}${qunt?.xero_code}.${sku?.xero_code}`
    } else {
      return 'N/A'
    }
  } else if (od?.name === 'BULK SELF PICKUP') {
    if (product_name && qunt?.xero_code && sku?.xero_code) {
      return `${product_name}${qunt?.xero_code}.${sku?.xero_code}`
    } else {
      return 'N/A'
    }
  } else if (od?.name === 'BULK AUGERED') {
    if (product_name && sku?.xero_code) {
      return `${product_name}A.${sku?.xero_code}`
    } else {
      return 'N/A'
    }
  }
  return 'N/A'
}

export { HandleCalculateTonsActual, xeroCodeGenerator }
