import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer'
import interreg from './../../../assets/PDFFonts/Inter-Regular.ttf'
import interbld from './../../../assets/PDFFonts/Inter-Bold.ttf'

Font.register({
  family: 'Inter',
  src: interreg,
})

Font.register({
  family: 'InterB',
  src: interbld,
})

// Create styles
const styles = StyleSheet.create({
  inline: {
    display: 'inline-block',
    fontSize: 8,
    lineHeight: 1.5,
  },
  heading: {
    fontSize: 17,
    lineHeight: 1.5,
    fontWeight: 700,
    fontFamily: 'InterB',
  },

  bold: {
    fontSize: 10,
    lineHeight: 1.5,
    fontWeight: 700,
    fontFamily: 'InterB',
  },

  bold9: {
    fontSize: 10,
    lineHeight: 1.5,
    fontWeight: 700,
    fontFamily: 'InterB',
  },

  bold9right: {
    fontSize: 8,
    lineHeight: 1.5,
    fontWeight: 700,
    marginLeft: 'auto',
    fontFamily: 'InterB',
  },

  bold10right: {
    fontSize: 8,
    lineHeight: 1.5,
    fontWeight: 700,
    fontFamily: 'InterB',
  },

  reg8: {
    color: 'blue',
    fontSize: 8,
    lineHeight: 1.5,
    fontWeight: 400,
    fontFamily: 'Inter',
  },

  reg9: {
    fontSize: 8,
    lineHeight: 0,
    fontWeight: 400,
    fontFamily: 'Inter',
  },

  reg10: {
    color: 'red',
    fontSize: 8,
    lineHeight: 1.5,
    fontWeight: 400,
    fontFamily: 'Inter',
  },

  page: {
    paddingTop: 10,
    paddingBottom: 30,
    paddingHorizontal: 10,
  },

  bottom: {
    position: 'absolute',
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'black',
    lineHeight: 1.2,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',

    lineHeight: 1.2,

    alignItems: 'self-start',
  },

  flexCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    lineHeight: 1.2,
  },

  margin_left: {
    marginLeft: 'auto',
  },

  tables: {
    backgroundColor: '#EFEFEF',
    margin: '0px',
    border: '0.5px solid black',
  },

  td: {
    width: '11.11%',
    padding: '5px 5px',
    border: '0.5px solid black',
  },

  th: {
    width: '11.11%',
    padding: '5px 5px',
    border: '0.5px solid black',
    backgroundColor: '#E8E8E8',
  },

  id: {
    width: '100%',
    padding: '5px 10px',
    border: '0.5px solid black',
    backgroundColor: '#E8E8E8',
  },

  layer2: {
    paddingLeft: 0,
  },
  layer3: {
    paddingLeft: 10,
  },
})

// Create Document Component
const DailyReport = (data) => {
  const dt = data?.item ?? null
  const map_dt = data?.map_data ?? null

  if (!dt || !map_dt) {
    return (
      <Document>
        <Page
          onRender={(e) => console.log(e)}
          size="A4"
          orientation="landscape"
          style={styles.page}
        >
          Error
        </Page>
      </Document>
    )
  }

  const formatDate = (date) => {
    if (!date) return 'No Date Provided' // Handle null or undefined
    const d = new Date(date)
    if (isNaN(d)) return 'No Date Provided' // Handle invalid dates
    const month = (d.getMonth() + 1).toString().padStart(2, '0')
    const day = d.getDate().toString().padStart(2, '0')
    const year = d.getFullYear()
    return `${year}-${month}-${day}`
  }

  const returnDate = () => {
    const currentdate = new Date()
    return `${currentdate.getDate()}/${
      currentdate.getMonth() + 1
    }/${currentdate.getFullYear()} - ${currentdate.getHours()}:${currentdate.getMinutes()}:${currentdate.getSeconds()}`
  }

  const zones = (data, map_dt) => {
    return (
      <>
        <Text style={styles.bold9right}>Date: {returnDate()}</Text>

        {Object.keys(data).map((firstLayer, i) => {
          return (
            <View
              break={i > 0}
              key={i}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <div
                style={{
                  width: '100px',
                }}
              >
                <Text style={styles.reg9}>
                  ZONE:{' '}
                  <Text style={styles.reg8}>
                    {map_dt.dzone.find((x) => x.id === Number(firstLayer))?.name}{' '}
                  </Text>
                </Text>
              </div>

              {Object.keys(data[firstLayer]).map((secondLayer, j) => (
                <>
                  <div
                    style={{
                      width: '120px',
                      paddingTop: 2,
                      paddingBottom: 2,
                    }}
                  >
                    <Text style={styles.reg9}>
                      TYPE:{' '}
                      <Text style={styles.reg8}>
                        {map_dt.otype.find((x) => x.id === Number(secondLayer))?.name}{' '}
                      </Text>
                    </Text>
                  </div>

                  {Object.keys(data[firstLayer][secondLayer]).map((thirdLayer, k) => (
                    <>
                      <div
                        style={{
                          width: '150px',
                          paddingBottom: 2,
                        }}
                      >
                        <Text style={styles.reg9}>
                          MACHINE:{' '}
                          <Text style={styles.reg8}>
                            {map_dt.machine.find((x) => x.id === Number(thirdLayer))?.name}
                          </Text>
                        </Text>
                      </div>

                      <div style={{ width: '100%' }}>
                        {data[firstLayer][secondLayer][thirdLayer].map((data, l) => (
                          <View
                            wrap={false}
                            key={l}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',

                              borderBottom: '0.2px solid gray',
                              marginBottom: '5px',
                            }}
                          >
                            {/* Column 1 */}
                            <View
                              style={{
                                width: '120px',
                                paddingTop: 2,
                                paddingBottom: 2,
                              }}
                            >
                              <Text style={styles.reg9}>
                                <Text style={styles.bold10right}>{data.id}</Text> |{' '}
                                {formatDate(data.created_at)}
                              </Text>
                              <Text style={styles.reg9}>{data.order_note}</Text>
                            </View>

                            {/* Column 2 */}
                            <View
                              style={{
                                width: '100px',
                                paddingTop: 2,
                                paddingBottom: 2,
                              }}
                            >
                              <Text style={styles.reg9}>
                                {data?.customer_address?.resident_name ?? ''}
                              </Text>
                            </View>

                            {/* Column 3 */}
                            <View
                              style={{
                                width: '100px',
                                paddingTop: 2,
                                paddingBottom: 2,
                              }}
                            >
                              <Text style={styles.reg9}>
                                {data?.customer_address?.resident_contact ?? ''}
                              </Text>
                            </View>

                            {/* Column 4 */}
                            <View
                              style={{
                                width: '200px',
                                paddingTop: 2,
                                paddingBottom: 2,
                              }}
                            >
                              <Text style={styles.reg9}>
                                {data?.order_list[0]?.order_list_products.length >= 2 && (
                                  <Text style={styles.bold10right}>
                                    MIX - {Number(data.tons_ordered).toFixed(3)}
                                    {'\n'}
                                  </Text>
                                )}

                                {data?.order_list[0]?.order_list_products.length >= 2
                                  ? data?.order_list[0]?.order_list_products.length >= 1 &&
                                    data?.order_list[0]?.order_list_products.map((data, i) => (
                                      <Text key={i} style={styles.reg10}>
                                        {data.product_name ?? ''}{' '}
                                        {Number(data.quantity).toFixed(3) ?? 100} {'\n'}
                                      </Text>
                                    ))
                                  : data?.order_list[0]?.order_list_products.length >= 1 &&
                                    data?.order_list[0]?.order_list_products.map((datas, i) => (
                                      <Text key={i} style={styles.bold10right}>
                                        {datas.product_name ?? ''}
                                        {', '}
                                        {datas.quantity}
                                        {' x '}
                                        {datas.quantitytype?.name}
                                        {' Bags'}
                                        {', '}
                                        {(
                                          datas.quantity * (datas.quantitytype?.quantity_value ?? 1)
                                        ).toFixed(3)}
                                        {'T'}
                                      </Text>
                                    ))}
                              </Text>
                            </View>

                            {/* Column 5 */}
                            <View
                              style={{
                                width: '100px',
                                paddingTop: 2,
                                paddingBottom: 2,
                              }}
                            >
                              <Text style={styles.bold10right}>
                                {data?.custom_spread_rate ?? ''}
                              </Text>
                            </View>

                            {/* Column 6 */}
                            <View
                              style={{
                                width: '100px',
                                paddingTop: 2,
                                paddingBottom: 2,
                              }}
                            >
                              <Text style={styles.bold10right}>
                                {data.dockets_ready == 1
                                  ? 'READY'
                                  : data.dockets_ready == 2
                                  ? 'WILL ADVICE'
                                  : data.dockets_ready === 3
                                  ? 'IN PROGRESS'
                                  : ''}
                              </Text>
                              <Text style={styles.reg9}>
                                {data.scheduled_date ? `(${formatDate(data.scheduled_date)})` : ''}
                              </Text>
                            </View>
                          </View>
                        ))}
                      </div>
                    </>
                  ))}
                </>
              ))}
            </View>
          )
        })}
      </>
    )
  }
  return (
    <Document>
      <Page
        onRender={(e) => console.log(e)}
        size="A4"
        orientation="landscape"
        wrap={false}
        style={styles.page}
      >
        {zones(dt, map_dt)}
      </Page>
    </Document>
  )
}

export default DailyReport
