import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer'
import interreg from './../../../assets/PDFFonts/Inter-Regular.ttf'
import interbld from './../../../assets/PDFFonts/Inter-Bold.ttf'
import { DateRangePicker } from 'rsuite'
import { object } from 'prop-types'

Font.register({
  family: 'Inter',
  src: interreg,
})

Font.register({
  family: 'InterB',
  src: interbld,
})

// Create styles
const styles = StyleSheet.create({
  heading: {
    color: 'black',
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '10px',
    paddingLeft: '5px',
  },
  subHeading: {
    color: 'blue',
    fontSize: '12px',
    marginTop: '5px',
    marginBottom: '5px',
    paddingLeft: '5px',
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
    lineHeight: 1.2,
    alignItems: 'self-start',
  },
  th: {
    width: '100%',
    margin: '0',
    padding: '5px',
    fontSize: '12px',
    fontWeight: 'bold',
    borderBottom: '1px solid black',
  },

  tablebody: { display: 'flex', flexDirection: 'row', lineHeight: 1.2, alignItems: 'self-start' },
  tr: {
    width: '100%',
    margin: '0',
    padding: '5px',
    fontSize: '10px',
  },
  totalParent: {
    width: '440px',
    display: 'flex',
  },
  total: {
    width: '50px',
    borderTop: '1px solid black',
    padding: '10px',
    fontSize: '10px',
    fontWeight: 'bold',
    marginLeft: 'auto',
  },
})

// Create Document Component
const Spread = (data) => {
  const totalTons = 0
  const dt = data?.item ?? null
  const map_dt = data?.map_data ?? null

  if (!dt || !map_dt) {
    return (
      <Document>
        <Page size="A4" orientation="portrait" style={styles.page}>
          Error
        </Page>
      </Document>
    )
  }

  const formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  const returnDate = () => {
    var currentdate = new Date()
    var datetime =
      currentdate.getDate() +
      '/' +
      (currentdate.getMonth() + 1) +
      '/' +
      currentdate.getFullYear() +
      ' - ' +
      currentdate.getHours() +
      ':' +
      currentdate.getMinutes() +
      ':' +
      currentdate.getSeconds()

    return datetime
  }

  const zones = (data, map_dt) => {
    return (
      <>
        <Text style={styles.heading}>Tonnes Spread This Month</Text>
        <div style={styles.table}>
          <Text style={styles.th}>Customer Name</Text>
          <Text style={styles.th}>Order Date</Text>
          <Text style={styles.th}>Tonnes</Text>
        </div>

        {Object.keys(data).map((zone) => {
          //console.log('spread data', data)
          return (
            <>
              <Text key={zone} style={styles.heading}>
                {map_dt.dzone.find((x) => x.id === Number(zone))?.name}
              </Text>

              {Object.keys(data[zone]).map((product, i) => {
                //console.log(product)
                return (
                  <>
                    <Text key={product} style={styles.subHeading}>
                      {map_dt.product.find((x) => x.id === Number(product))?.product_docket}
                    </Text>

                    {data[zone][product].map((dt, j) => {
                      return (
                        <>
                          <div key={i} style={styles.tablebody}>
                            <Text style={styles.tr}>{dt.customer.account_name}</Text>
                            <Text style={styles.tr}>{formatDate(dt.created_at)}</Text>
                            <Text style={styles.tr}>
                              {/* {map_dt.product.find((x) => x.id === Number(product))?.id}|{dt.id} */}
                              {(
                                (map_dt.order_list_products.find(
                                  (x) =>
                                    x.order_id == Number(dt.id) &&
                                    x.product_mix_id ==
                                      map_dt.product.find((x) => x.id === Number(product))?.id,
                                )?.mix_percentage /
                                  100) *
                                dt.tons_ordered
                              ).toFixed(3)}

                              {/*  {(
                                (dt.order_list[0]?.order_list_products[j]?.mix_percentage / 100) *
                                dt.tons_ordered
                              ).toFixed(3)} */}
                            </Text>
                          </div>
                        </>
                      )
                    })}

                    {data[zone][product].length > 1 ? (
                      <div style={styles.totalParent}>
                        <Text style={styles.total}>
                          {data[zone][product]
                            .map(
                              (dt, j) =>
                                (map_dt.order_list_products.find(
                                  (x) =>
                                    x.order_id == Number(dt.id) &&
                                    x.product_mix_id ==
                                      map_dt.product.find((x) => x.id === Number(product))?.id,
                                )?.mix_percentage /
                                  100) *
                                dt.tons_ordered,
                            )
                            .reduce(function (previousValue, currentValue) {
                              return Number(previousValue) + Number(currentValue)
                            }, 0)
                            .toFixed(3)}
                        </Text>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                )
              })}
            </>
          )
        })}
      </>
    )
  }
  return (
    <Document>
      <Page size="A4" orientation="portrait" wrap style={styles.page}>
        {zones(dt, map_dt)}
      </Page>
    </Document>
  )
}

export default Spread
