import React, { useState, useEffect } from 'react'
import swal from 'sweetalert2'
import axios from 'axios'
import $ from 'jquery'
import Preloader from 'src/views/preloader/Preloader'
import { useHistory, useParams } from 'react-router-dom'
import useUnsavedChangesWarning from 'src/hooks/UnsavedChangesWarning'
import { xeroCodeGenerator } from './utils/Functions'

//services
import {
  getOrders,
  createOrders,
  updateOrders,
  getOrderTypeSingle,
  getProductMixSingle,
  getProductSingle,
} from 'src/services'

//components
import {
  OrderStatusSection,
  CustomerInformationSection,
  OrderInformation,
  ProductInformation,
  InvoiceLineItems,
  BreadCrumb,
  Button,
  Card,
} from 'src/components'
import { compose } from 'redux'

const CreateOrUpdate = () => {
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning()
  const history = useHistory()
  const { id } = useParams()
  const isUpdating = id !== undefined
  const [loading, setLoading] = useState(true)
  const [errorList, setErrorList] = useState([])

  /* Pre loaded data */
  const [orderInformationData, setOrderInformationData] = useState({
    order_type: [],
    sub_order_type: [],
    delivery_zone: [],
    sku_location: [],
    agent: [],
    machine: [],
    crop: [],
    operator: [],
    product_mix: [],
    quantity: [],
    line_item: [],
  })
  const [customer, setCustomer] = useState([])

  /* All from Orders */
  const [inputList, setInputList] = useState({
    created_by: 'Admin',
    tons_ordered: '',
    tons_actual: '',
    order_status: 0,
    dockets_ready: '3',
    dockets_ready_time: null,
    tons_complete: '0',
    tons_complete_time: null,
    dockets_complete: '0',
    dockets_complete_time: null,
    product_information: [
      {
        docket_numbers: [],
        product_id: null,
        product_type: null,
        order_list_products: [],
      },
    ],
    other_line_items: [],
    operators: [
      {
        operators_id: '',
      },
    ],
    order_types: [
      {
        name: '',
      },
    ],
    user_name: '', // Add this field
    agent_id: '', // Add this field
    order_type: '', // Add this field
    sub_order_type: '', // Add this field
    order_delivery_zone: '', // Add this field
    sku_location: '', // Add this field
    has_spread_charge_rate: '', // Add this field
    machine_id: '', // Add this field
    scheduled_date: '', // Add this field
    order_note: '', // Add this field
    customer_id: '', // Add this field
    customer_address: '', // Add this field
    account_name: '', // Add this field
    account_contact_name: '', // Add this field
    terms: '', // Add this field
    customer_terms_name: '', // Add this field
    customer_terms_highlight: '', // Add this field
    customer_reference: '', // Add this field
    quoted_price: '', // Add this field
    driver_note: '', // Add this field
    admin_note: '', // Add this field
    product_mix: [], // Add this field
  })

  const [spreadchargerates, setSpreadChargeRates] = useState([])
  const [OrderStatus, setOrderStatus] = useState('In Progress')

  /* That can be refactored further */
  const [addressSelect, setAddressSelect] = useState([])
  const [customerAddress, setCustomerAddress] = useState([])
  const [customerSelectOptions, setCustomerSelectedOptions] = useState([])
  const [orderTypeName, setOrderTypeName] = useState()

  /* Conditions for the Order status */
  const orderStatus = async () => {
    try {
      const docketsReady = inputList.dockets_ready
      const docketsComplete = inputList.dockets_complete
      const tonsComplete = inputList.tons_complete

      let orderStatus = ''

      switch (`${docketsReady}_${docketsComplete}_${tonsComplete}`) {
        case '2_0_0':
        case '2_1_0':
        case '2_0_1':
        case '2_1_1':
          orderStatus = 'Will Advise'
          break
        case '1_0_0':
        case '1_1_0':
        case '1_0_1':
          orderStatus = 'Ready'
          break
        case '1_1_1':
          orderStatus = 'Complete'
          break
        default:
          break
      }

      if (docketsReady === '3') {
        orderStatus = 'In Progress'
      }

      if (inputList.order_status === 1) {
        orderStatus = 'Complete'
      }
      setOrderStatus(orderStatus)
    } catch (error) {
      setTimeout(() => {
        $('#dataTable').DataTable({
          scrollX: false,
          pageLength: 100,
        })
      }, 10)
      setLoading(false)
      // Handle errors here
      console.error(error)
    }
  }

  /* Conditions for Customer Information */
  const customerInformation = async () => {
    try {
      const res = await getOrders()
      if (res.data.status === 200) {
        const newCustomers = res.data.customerXero.body.Contacts.map((contact) => {
          const mobilePhone = contact.Phones.find((phone) => phone.PhoneType === 'MOBILE')
          const label = mobilePhone
            ? `${contact.CompanyNumber} | ${contact.Name} | ${mobilePhone.PhoneNumber}`
            : `${contact.CompanyNumber} | ${contact.Name}`
          return {
            value: contact.ContactID,
            label: label,
          }
        })

        // Replace existing customers with new customers
        setCustomer(newCustomers)

        const getCustomer = await axios.get(
          `api/edit_customers_orders/${res.data.orders.customer_id}`,
        )
        const customerData = getCustomer.data.customer

        setCustomerSelectedOptions({
          value: customerData.id,
          label: `${customerData.code} | ${customerData.account_name} | ${customerData.phone_mobile}`,
        })

        const updatedInputList = {
          ...inputList,
          customer_id: customerData.id,
          account_name: customerData.account_name,
          account_contact_name: customerData.account_contact_name,
          customer_terms_name: customerData.customer_terms.name,
          customer_terms_highlight: customerData.customer_terms.highlight,
          ...res.data.orders,
        }

        setInputList(updatedInputList)
        setCustomerAddress(customerData.customer_address)
      }

      setTimeout(() => {
        $('#dataTable').DataTable({
          scrollX: true,
          pageLength: 100,
        })
      }, 10)

      setLoading(false)
    } catch (error) {
      setTimeout(() => {
        $('#dataTable').DataTable({
          scrollX: false,
          pageLength: 100,
        })
      }, 10)

      setLoading(false)
      console.error(error)
    }
  }

  /* Conditions for Order information */
  const orderInformation = async () => {
    try {
      const res = await getOrders()
      if (res.data.status === 200) {
        const order_types = res.data.order_types.map((data) => {
          return {
            value: data.id,
            label: data.name,
          }
        })

        const sub_order_type = res.data.sub_order_type.map((data) => {
          return {
            value: data.id,
            label: data.name,
          }
        })

        const delivery_zone = res.data.delivery_zones.map((data) => {
          return {
            value: data.id,
            label: data.name,
          }
        })

        const sku_location = res.data.sku_location.map((data) => {
          return {
            value: data.id,
            label: data.name,
          }
        })

        const agent = res.data.agent.map((data) => {
          return {
            value: data.id,
            label: data.name,
          }
        })

        const machine = res.data.machine.map((data) => {
          return {
            value: data.id,
            label: data.name,
          }
        })

        const crop = res.data.crop.map((data) => {
          return {
            value: data.id,
            label: data.name,
          }
        })

        const operator = res.data.operator.map((data) => {
          return {
            value: data.id,
            label: data.fname,
          }
        })

        // Update the state with order_types
        setOrderInformationData((prevData) => ({
          ...prevData,
          order_type: order_types,
          sub_order_type: sub_order_type,
          delivery_zone: delivery_zone,
          sku_location: sku_location,
          agent: agent,
          machine: machine,
          crop: crop,
          operator: operator,
        }))
      }
    } catch (error) {}
  }

  /* Conditions for Product information */
  const productInformation = async () => {
    try {
      const res = await getOrders()
      //console.log(res.data)
      if (res.data.status === 200) {
        const products = res.data.products.map((data) => {
          return {
            value: data.id,
            label: data.product_name,
            selected: 'product',
          }
        })

        const product_mix = res.data.productmix.map((data) => {
          return {
            value: data.id,
            label: data.product_docket,
            selected: 'product_mix',
          }
        })

        const quantity = res.data.quantity.map((data) => {
          return {
            value: data.id,
            label: data.name,
            quantity_value: data.quantity_value,
          }
        })

        // Combine the existing product_mix with the new products
        const updatedProductMix = [...product_mix, ...products]

        // Update the state with order_types
        setOrderInformationData((prevData) => ({
          ...prevData,
          product_mix: updatedProductMix,
          quantity: quantity,
        }))
      }
    } catch (error) {}
  }

  /* Conditions for Product information */
  const invoiceLineItems = async () => {
    try {
      const res = await getOrders()
      if (res.data.status === 200) {
        const line_item = res.data.line_item.map((data) => {
          return {
            value: data.id,
            label: data.name,
            selected: 'product',
          }
        })

        // Update the state with order_types
        setOrderInformationData((prevData) => ({
          ...prevData,
          line_item: line_item,
        }))
      }
    } catch (error) {}
  }

  useEffect(() => {
    const loadPrice = async () => {
      const list = { ...inputList }
      for (let i = 0; i < list.product_information.length; i++) {
        for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
          list.product_information[i].order_list_products[j].xero_code = xeroCodeGenerator(
            orderInformationData.sku_location,
            orderInformationData.order_type,
            orderInformationData.machine,
            inputList?.product_information[i]?.order_list_products[j]?.product_name,
            spreadchargerates,
            inputList?.product_information[i]?.order_list_products[0]?.spread_charge_rate,
            orderInformationData.quantity,
            inputList.product_information[i].order_list_products.length > 1
              ? 6
              : inputList?.product_information[i]?.order_list_products[j]?.quantity_type,
            inputList,
          )

          try {
            const agents = await axios.post('/api/get_xero_price', {
              code: list.product_information[i].order_list_products[j].xero_code,
            })

            if (list?.product_information[i]?.order_list_products[j]?.unit_price) {
              list.product_information[i].order_list_products[j].unit_price =
                agents.data?.item?.body?.Items[0]?.SalesDetails?.UnitPrice
            }
          } catch (err) {
            list.product_information[i].order_list_products[j].unit_price = 'N/A'
          }
        }
      }

      setInputList({ ...inputList, list })
    }

    loadPrice()
  }, [
    inputList?.product_information[0]?.order_list_products[0]?.spread_charge_rate,
    inputList.order_type,
    inputList.sku_location,
    inputList.order_delivery_zone,
    inputList.machine_id,
  ])

  useEffect(() => {
    const list = { ...inputList }

    let tons_actual = 0
    for (let i = 0; i < list.product_information.length; i++) {
      for (let j = 0; j < list.product_information[i].docket_numbers.length; j++) {
        if (Number(list.product_information[i].docket_numbers[j].weight))
          tons_actual += Number(list.product_information[i].docket_numbers[j].weight)
      }
    }
    list.tons_actual = tons_actual

    orderInformation()
    orderStatus()
    customerInformation()
    productInformation()
    invoiceLineItems()
  }, [inputList])

  //add docker fields - Add button
  const handleAddDocket = (e, i) => {
    e.preventDefault()

    // Create a deep copy of the inputList
    const list = { ...inputList }

    // Ensure product_information array exists at index i
    if (!list.product_information[i]) {
      list.product_information[i] = { docket_numbers: [] }
    }

    // Add a new docket_numbers object to the array
    list.product_information[i].docket_numbers.push({
      sku_location: '',
      docket_number: '',
      docket_date: '',
      weight: '',
      imageName: '',
      imageURL: '',
    })

    // Update the state with the modified list
    setInputList({ ...list })

    // Calculate sum (optional)
    var sum = 0
    list.product_information[i].docket_numbers.forEach((numbers) => (sum += Number(numbers.weight)))
  }

  //remove docker fields - Remove button
  const handleRemoveDocket = (e, docketIndex, i) => {
    setDirty()

    e.preventDefault()
    const list = { ...inputList }
    list.product_information[i].docket_numbers.splice(docketIndex, 1)
    setInputList(list)
  }

  //handle changes for checkboxes
  const handleRadio = (e) => {
    setDirty()

    const { name, value } = e.target
    const list = { ...inputList }
    list[name] = value
    setInputList(list)
  }

  //handle changes for checkboxes
  const handleCheckBox = (e) => {
    setDirty()

    const { name, checked } = e.target
    const nameTime = name.concat('_time')
    const list = { ...inputList }

    var MyDate = new Date()
    var MyDateString

    MyDate.setDate(MyDate.getDate())
    MyDateString =
      MyDate.getFullYear() +
      '-' +
      ('0' + (MyDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + MyDate.getDate()).slice(-2)

    let total_rows = 0
    for (let i = 0; i < inputList.product_information.length; i++) {
      for (let j = 0; j < inputList.product_information[i].docket_numbers.length; j++) {
        total_rows += 1
      }
    }

    if (name === 'dockets_complete' && (total_rows <= 0 || inputList.tons_complete === 0)) {
      swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Atleast one docket should be added and tons complete should be checked',
      })
      return
    }
    if (checked) {
      list[name] = checked ? '1' : '0'
      list[nameTime] = MyDateString

      setInputList(list)
    } else {
      swal
        .fire({
          title: 'Are you sure you want to proceed?',
          text: 'Unchecking will reset the Data and Time and may effect Order Complete Status!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Proceed!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            swal.fire(
              'Unchecked!',
              'Date and Time has been reset. Order is in progress.',
              'Success',
            )
            list[nameTime] = null
            list[name] = '0'
            setInputList(list)
          } else if (result.isDenied) {
            swal.fire('Changes are not saved', '', 'info')
            list[name] = '1'
            setInputList(list)
          }
        })
    }
  }

  //load the additional data when select the addresses
  const handleChangeAddress = async (selectedOption) => {
    setDirty()

    // Update the inputList state with the selected option's value
    const selectedValue = selectedOption ? selectedOption.value : ''
    setInputList((prevState) => ({
      ...prevState,
      customer_address: selectedValue,
    }))

    // Fetch additional address details based on the selected value
    if (selectedValue) {
      try {
        const addressDetails = await axios.get(`/api/edit_addresse/${selectedValue}`)
        if (addressDetails.data.status === 200) {
          setAddressSelect(addressDetails.data.address)
        }
      } catch (error) {
        console.error('Error fetching address details:', error)
        // Handle error appropriately
      }
    } else {
      // Reset addressSelect if no option is selected
      setAddressSelect([])
    }
  }

  //handle changes for customer information
  const handleChangeLevel1 = async (e) => {
    const { name, value } = e.target
    const list = { ...inputList }

    if (name === 'order_type') {
      getOrderTypeSingle(value)
        .then((res) => {
          if (res.data.status === 200) {
            setOrderTypeName(res.data.order_types.name)
            list.order_types.name = res.data.order_types.name
            list.has_spread_charge_rate = res.data.order_types.has_spread_charge_rate
            // setInputList(list) // Uncomment if needed
          } else {
            setOrderTypeName([])
            list.order_types.name = 'Please select the order type'
          }
        })
        .catch(() => {
          history.push('/orders')
        })
        .finally(() => {
          setLoading(false)
        })
    }

    setDirty()

    list[name] = value
    setInputList(list)

    //xeroCodeGenerator(Code, inputList)
  }

  //handle changes for customer information
  const handleChangeLevel2 = (e, i, arrayname) => {
    setDirty()

    const { name, value } = e.target
    const list = { ...inputList }
    list[arrayname][i][name] = value
    setInputList(list)
  }

  //handle changes for other line items quantity
  const handleBlurDecimalLevel2 = (e, i, arrayname) => {
    setDirty()

    const { name, value } = e.target
    const list = { ...inputList }

    var num = parseFloat(value)
    var cleanNum = num.toFixed(3)

    list[arrayname][i][name] = cleanNum
    setInputList(list)
  }

  //handle changes for checkboxes
  const HandleCompleted = (e) => {
    setDirty()

    const { name, checked } = e.target
    const list = { ...inputList }

    if (checked) {
      list.order_status = 1
      swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Order will be marked complete',
      })
    } else {
      list.order_status = 0
    }

    setInputList(list)
  }

  const handleChangeCustomerID = (selectedOption) => {
    setDirty()

    setCustomer([])

    if (selectedOption) {
      const selectedCustomerId = selectedOption.value

      // Set selected customer in state
      setCustomerSelectedOptions(selectedOption)

      // Fetch details for the selected customer
      axios
        .get(`api/edit_customers/${selectedCustomerId}`)
        .then((res) => {
          if (res.data.status === 200) {
            const customerData = res.data.customer

            // Update inputList with fetched customer details
            setInputList((prevState) => ({
              ...prevState,
              customer_id: customerData.id,
              account_name: customerData.account_name,
              account_contact_name: customerData.account_contact_name,
              terms: customerData.terms,
              customer_terms_name: customerData.customer_terms.name,
              customer_terms_highlight: customerData.customer_terms.highlight,
              customer_address: '', // Resetting the customer address field
            }))

            // Replace customerAddress state with new data
            setCustomerAddress(customerData.customer_address || [])

            // Reset addressSelect and the table display
            setAddressSelect([])
          } else {
            resetCustomerInfo()
          }
        })
        .catch((error) => {
          console.error('Error fetching customer data:', error)
          resetCustomerInfo()
        })
    } else {
      resetCustomerInfo()
    }
  }

  //Handle Operators to add
  const handleAddOperators = (e) => {
    setDirty()
    e.preventDefault()
    const list = { ...inputList }
    list.operators.push({ operators_id: '' })
    setInputList(list)
  }

  //Handle Operators to add
  const handleRemoveOperators = (e, i) => {
    setDirty()

    e.preventDefault()
    const list = { ...inputList }
    list.operators.splice(i, 1)
    setInputList(list)
  }

  //Handle Product mix onChange
  const handleChangeProductMix = (e, index) => {
    setDirty()

    const { value, name, selected } = e
    const list = { ...inputList }
    list.product_information[index].product_id = value
    list.product_information[index].product_type = selected
    setInputList(list)
  }

  //Load data into table
  const handleAddNewProduct = (e, index) => {
    setDirty()

    e.preventDefault()
    var min = 1
    var max = 100
    var rand = Math.floor(min + Math.random() * (max - min))
    e.target.disabled = true
    e.target.innerText = 'Adding...'

    const id = inputList.product_information[index].product_id
    const productType = inputList.product_information[index].product_type

    const resetAddButton = (event) => {
      event.target.disabled = false
      event.target.innerText = 'Add Product'
    }

    const isProductAlreadyAdded = inputList.product_information[index].order_list_products.some(
      (product) => product.product_mix_id === id && product.product_type === productType,
    )

    if (isProductAlreadyAdded) {
      swal.fire('Warning', 'Product or Product Mix already added', 'warning')
      resetAddButton(e) // Pass the event to the resetAddButton function
      return
    }

    const getProductPromise =
      productType === 'product_mix' ? getProductMixSingle(id) : getProductSingle(id)

    getProductPromise
      .then((res) => {
        if (res.data && res.data.status === 200) {
          const list = { ...inputList }
          const newData = {
            product_mix_id: res.data.product_mix?.id || res.data.product?.id,
            product_name: res.data.product_mix?.product_docket || res.data.product?.product_name,
            spread_charge_rate: '',
            quantity: '',
            quantity_type: '',
            xero_code: '',
            unit_price: rand,
            mix_percentage: '100',
            product_type: productType,
          }

          list.product_information[index].order_list_products.push(newData)
          setInputList(list)
          swal.fire(
            'Success',
            `${productType === 'product' ? 'Product' : 'Product Mix'} added successfully`,
            'success',
          )
        } else {
          swal.fire('Error', 'Unexpected error occurred', 'error')
        }

        setLoading(false)
        resetAddButton(e) // Pass the event to the resetAddButton function
      })
      .catch((error) => {
        swal.fire('Error', error.message, 'error')
        resetAddButton(e) // Pass the event to the resetAddButton function
      })
  }

  const resetCustomerInfo = () => {
    setInputList((prevState) => ({
      ...prevState,
      customer_id: '',
      account_name: '',
      account_contact_name: '',
      terms: '',
      customer_terms_name: '',
      customer_terms_highlight: '',
      customer_address: '', // Ensure this field is also reset here
    }))
    setCustomerAddress([])
    setAddressSelect([]) // Reset the address select and table
  }

  //calculate percentage value
  const percentatgeCalc = (e, i, j, arrayname, arrayname2) => {
    const list = { ...inputList }
    for (let i = 0; i < list.product_information.length; i++) {
      if (list.product_information[i].order_list_products.length > 1) {
        for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
          var percentage =
            (Number(list.tons_ordered).toFixed(3) *
              Number(list.product_information[i].order_list_products[j].mix_percentage).toFixed(
                3,
              )) /
            100

          list.product_information[i].order_list_products[j].quantity =
            Number(percentage).toFixed(3)
        }
      } else {
        for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
          list.product_information[i].order_list_products[j].mix_percentage = 100
        }
      }
    }
    setInputList(list)
  }

  //move to the nearest decimal point
  const handleBlurDecimalLevel1 = (e) => {
    setDirty()
    const { name, value } = e.target
    const list = { ...inputList }

    var num = parseFloat(value)
    var cleanNum = num.toFixed(3)

    list[name] = cleanNum
    setInputList(list)
  }

  const handleChangeLevel3 = (e, i, j, arrayname, arrayname2) => {
    setDirty()
    const { name, value } = e.target
    const list = { ...inputList }

    // Update the specific property in the nested structure
    list[arrayname][i][arrayname2][j][name] = value

    // Calculate the total weight and update tons_actual
    if (name === 'weight') {
      const totalWeight = list[arrayname].reduce((acc, item) => {
        return (
          acc +
          item[arrayname2].reduce(
            (innerAcc, innerItem) => innerAcc + parseFloat(innerItem.weight || 0),
            0,
          )
        )
      }, 0)
      list['tons_actual'] = totalWeight.toFixed(3)
    }

    setInputList(list)
  }

  const handleChangeLevel4 = (e, i, j, arrayname, arrayname2) => {
    setDirty()
    const { name, value } = e.target
    const list = { ...inputList }
    list[arrayname][i][arrayname2][j][name] = value
  }

  // 3 decimal point
  const quantityTotalBlur = (e, i, j, arrayname, arrayname2) => {
    const { name, value } = e.target
    const list = { ...inputList }
    list[arrayname][i][arrayname2][j][name] = Number(value).toFixed(3)
    setInputList(list)
  }

  //enable disable quantity
  const enableQuantity = (e) => {
    e.preventDefault()
    const list = { ...inputList }
    document.getElementsByName('tons_ordered')[0].setAttribute('disabled', '')
    for (let i = 0; i < list.product_information.length; i++) {
      for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
        document.getElementsByName('mix_percentage')[j].setAttribute('disabled', '')
        document.getElementsByName('quantity')[j].removeAttribute('disabled')
        const quant_clr = document.getElementById('quantity')
        const mix_clr = document.getElementById('mix_percentage')
        quant_clr.style.color = 'blue'
        mix_clr.style.color = 'black'
      }
    }
  }

  //calculate tons value
  const quantityTotal = (e, i, j, arrayname, arrayname2) => {
    const list = { ...inputList }
    const quantity = []
    //get total tonnes
    for (let i = 0; i < list.product_information.length; i++) {
      if (list.product_information[i].order_list_products.length > 1) {
        for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
          quantity.push(+list.product_information[i].order_list_products[j].quantity).toFixed(3)
        }
      } else {
        for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
          quantity.push(+list.product_information[i].order_list_products[j].quantity).toFixed(3)
          list.product_information[i].order_list_products[j].mix_percentage = 100
        }
      }
    }
    list.tons_ordered = quantity.reduce((v1, v2) => v1 + v2, 0).toFixed(3)

    //get percentage
    for (let i = 0; i < list.product_information.length; i++) {
      if (list.product_information[i].order_list_products.length > 1) {
        for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
          list.product_information[i].order_list_products[j].mix_percentage = Number(
            (list.product_information[i].order_list_products[j].quantity * 100) / list.tons_ordered,
          ).toFixed(3)
        }
      } else {
        for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
          quantity.push(+list.product_information[i].order_list_products[j].quantity).toFixed(3)
          list.product_information[i].order_list_products[j].mix_percentage = 100
        }
      }
    }
    setInputList(list)
  }

  //enable disable product mix
  const enableMix = (e) => {
    e.preventDefault()
    const list = { ...inputList }
    document.getElementsByName('tons_ordered')[0].removeAttribute('disabled')
    for (let i = 0; i < list.product_information.length; i++) {
      for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
        document.getElementsByName('mix_percentage')[j].removeAttribute('disabled')
        document.getElementsByName('quantity')[j].setAttribute('disabled', '')
        const quant_clr = document.getElementById('quantity')
        const mix_clr = document.getElementById('mix_percentage')
        quant_clr.style.color = 'black'
        mix_clr.style.color = 'blue'
      }
    }
  }

  //handle image changes
  const handleImage = (e, docketIndex, i) => {
    setDirty()

    const list = { ...inputList }
    let reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    const imagename = e.target.files[0].name.split('.').slice(0, -1).join('.')
    reader.onload = function () {
      list.product_information[i].docket_numbers[docketIndex]['imageURL'] = reader.result
      list.product_information[i].docket_numbers[docketIndex]['imageName'] = imagename
      setInputList(list)
    }
    reader.onerror = function (error) {
      //console.log('Error: ', error)
    }
  }

  //Add Other Invoice Line Items
  const addLineItems = (e) => {
    setDirty()

    e.preventDefault()
    setInputList({
      ...inputList,
      other_line_items: [
        ...inputList.other_line_items,
        {
          type: '',
          description: '',
          quantity: '',
          amount: '',
        },
      ],
    })
  }

  //remove a product row
  const removeLineItems = (e, i) => {
    setDirty()

    e.preventDefault()
    const list = { ...inputList }
    list.other_line_items.splice(i, 1)
    setInputList(list)
  }

  //handle changes for other line items
  const handleChangeOtherLineItems = (e, i) => {
    setDirty()

    const { name, value } = e.target
    const list = { ...inputList }
    list.other_line_items[i][name] = value
    setInputList(list)
  }

  // calculate tons actuual
  const mixpercentage = (e) => {
    let sum = 0
    const list = { ...inputList }

    if (list.product_information[0].order_list_products.length > 0) {
      for (let i = 0; i < list.product_information.length; i++) {
        for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
          if (list.product_information[i].order_list_products[j].mix_percentage === 0) {
            swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'The mix percentage cannot be empty',
            })
            return false
          }
          sum += Number(list.product_information[i].order_list_products[j].mix_percentage)
        }

        if (sum !== 100) {
          swal.fire({
            icon: 'warning',
            title: 'Warning',
            text: 'The mix percentage should add up to 100%',
          })
          return false
        }

        sum = 0
      }
    }

    return true
  }

  //send data to the API
  const submitOrders = (e) => {
    e.preventDefault()
    const status = mixpercentage()
    const data = inputList
    const submitAction = () => createOrders(data)

    //console.log(data)
    createOrders(data).then((res) => {
      if (res.data && res.data.status === 200) {
        swal('Success', res.data.message, 'success')
        history.push('/order_types')
        //console.log('This is it')
      } else {
        //console.log('This')
      }
    })

    /* if (status) {
      if (id) {
        axios.put(`/api/update_orders/${id}`, inputList).then((res) => {
          if (res.data.status === 200) {
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: res.data.message,
            })
            setErrorList([])
            setPristine()
            history.push(`/orders/update/${res.data.id}`)
          } else if (res.data.status === 400) {
            setErrorList(res.data.errors)
          } else if (res.data.status === 404) {
            swal.fire({
              icon: 'Error',
              title: 'Error',
              text: res.data.message,
            })
            history.push(`/orders/update/${res.data.id}`)
          }
        })
      } else {
        console.log(inputList)
        axios.post(`/api/create_order`, inputList).then((res) => {
          if (res.data.status === 200) {
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: res.data.message,
            })
            setErrorList([])
            setPristine()
            history.push(`/orders/update/${res.data.id}`)
          } else if (res.data.status === 400) {
            swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'Please fill the required fields',
            })
            setErrorList(res.data.errors)
          }
        })
      }
    } */
  }

  const saveAndClose = (e) => {
    e.preventDefault()

    const status = mixpercentage()

    if (status) {
      if (id) {
        axios.put(`/api/update_orders/${id}`, inputList).then((res) => {
          if (res.data.status === 200) {
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: res.data.message,
            })
            setErrorList([])
            setPristine()
            history.push('/orders')
          } else if (res.data.status === 400) {
            setErrorList(res.data.errors)
          } else if (res.data.status === 404) {
            swal.fire({
              icon: 'Error',
              title: 'Error',
              text: res.data.message,
            })
            history.push('/orders')
          }
        })
      } else {
        axios.post(`/api/create_order`, inputList).then((res) => {
          if (res.data.status === 200) {
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: res.data.message,
            })
            setErrorList([])
            setPristine()
            history.push(`/orders`)
          } else if (res.data.status === 400) {
            swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'Please fill the required fields',
            })
            setErrorList(res.data.errors)
          }
        })
      }
    }
  }

  const duplicateOrder = (e) => {
    e.preventDefault()

    const status = mixpercentage()

    if (status) {
      axios.get(`/api/edit_profile`).then((res) => {
        if (res.data.status === 200) {
          const list = { ...inputList }
          list.created_by = res.data.user.id
          list.user_name = res.data.user.name
          setInputList(list)
        }
      })

      axios.post(`/api/create_order`, inputList).then((res) => {
        if (res.data.status === 200) {
          swal.fire({
            icon: 'success',
            title: 'Success',
            text: res.data.duplicate,
          })
          setPristine()
          setErrorList([])
          history.push(`/orders/update/${res.data.id}`)
        } else if (res.data.status === 400) {
          setErrorList(res.data.errors)
        }
      })
    }
  }

  //Data
  const title = 'Orders'

  //Data
  const isanupdate = isUpdating ? 'Update' : 'Add'

  //Data
  const breadcrumb = [
    {
      title: title,
      name: 'order',
      subtitle: 'Add ' + title,
      subtitleroute: '',
    },
  ]

  //Data
  const cardValues = [
    {
      title: 'Order',
      addorupdate: isanupdate,
    },
  ]

  //Data
  const buttonData = [
    { title: id ? 'Update' : 'Save', variants: 'btn btn-primary', routes: '/' },
    {
      title: id ? 'Update And Close' : 'Save And Close',
      variants: 'btn btn-primary ms-3',
      routes: '/',
    },
  ]

  //Selected customer addresses
  const options =
    customerAddress?.map((item) => ({
      value: item.id,
      label: item.address,
    })) || []

  if (loading) {
    return <Preloader />
  }

  return (
    <div>
      <BreadCrumb data={breadcrumb} />
      <div className="content">
        <div className="card card-primary">
          <Card data={cardValues}>
            <div className="d-flex flex-row justify-content-end">
              <div className="">
                <Button
                  onClick={(e) => submitOrders(e)}
                  label={isUpdating ? 'Update' : 'Save'}
                  className="btn-primary"
                />
                <Button
                  onClick={(e) => submitOrders(e)}
                  label={isUpdating ? 'Update And Close' : 'Save And Close'}
                  className="btn-primary ms-3"
                />
                {isUpdating && (
                  <Button
                    onClick={(e) => submitOrders(e)}
                    label="Duplicate Entry"
                    className="btn-info ms-3"
                  />
                )}
              </div>
            </div>

            {/* Order Status */}
            <OrderStatusSection
              {...{
                inputList,
                handleRadio,
                handleCheckBox,
                handleChangeLevel1,
                OrderStatus,
                errorList,
                HandleCompleted,
              }}
            />

            <hr></hr>

            {/* Customer information */}
            <CustomerInformationSection
              {...{
                customer,
                options,
                inputList,
                handleChangeLevel1,
                handleChangeCustomerID,
                customerSelectOptions,
                handleChangeAddress,
                addressSelect,
              }}
            />

            <hr></hr>

            {/* Order Information */}
            <OrderInformation
              {...{
                inputList,
                handleChangeLevel1,
                handleChangeLevel2,
                handleAddOperators,
                handleRemoveOperators,
                addressSelect,
                orderInformationData,
              }}
            />

            <hr></hr>

            {/* Product Information */}
            <ProductInformation
              {...{
                errorList,
                inputList,
                orderInformationData,
                handleAddNewProduct,
                handleChangeProductMix,
                percentatgeCalc,
                handleBlurDecimalLevel1,
                handleChangeLevel1,
                enableQuantity,
                quantityTotalBlur,
                handleChangeLevel3,
                handleChangeLevel4,
                quantityTotal,
                enableMix,
                handleImage,
                handleAddDocket,
                handleRemoveDocket,
                spreadchargerates,
                xeroCodeGenerator,
              }}
            />

            <hr></hr>

            <InvoiceLineItems
              {...{
                errorList,
                inputList,
                addLineItems,
                handleChangeOtherLineItems,
                orderInformationData,
                handleBlurDecimalLevel2,
                addLineItems,
                removeLineItems,
              }}
            />

            <div className="mt-4">
              <button type="submit" className="btn btn-primary">
                {id ? 'Update' : 'Save'}
              </button>
              <button
                type="submit"
                onClick={(e) => saveAndClose(e)}
                className="btn btn-primary ms-3"
              >
                {id ? 'Update And Close' : 'Save And Close'}
              </button>
              {id != null && (
                <button
                  //onClick={(e) => duplicateOrder(e)}
                  className="btn btn-info ms-3"
                >
                  Duplicate Entry
                </button>
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default CreateOrUpdate
