import React, { useState, useEffect } from 'react'
import { useHistory, NavLink } from 'react-router-dom'
import axios from 'axios'
import { CFormSelect } from '@coreui/react'
import swal from 'sweetalert'
import Preloader from '../preloader/Preloader'

const defaultXeroCustomer = {
  customer_phone: [
    {
      phone_type: '',
      phone_number: '',
      phone_area_code: '',
      phone_country_code: '',
    },
  ],
  customer_address: [
    {
      address: '',
      p_location: '',
      del_zone: '',
      resident_name: '',
      resident_contact: '',
      resident_name_two: '',
      resident_contact_two: '',
      location_note: '',
    },
  ],
}

function Create(props) {
  const isUpdateMode = Boolean(props.match.params.id)
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const [termList, setTermList] = useState([])

  const [xeroCustomer, setXeroCustomer] = useState(defaultXeroCustomer)

  const [xeroContact, setXeroContact] = useState({
    Phones: [
      {
        PhoneType: '',
        PhoneNumber: '',
        PhoneAreaCode: '',
        PhoneCountryCode: '',
      },
    ],
  })

  const [delList, setDelList] = useState([])
  const [errorList, setError] = useState([])

  const handleAddInput = (e) => {
    e.preventDefault()
    setXeroCustomer((prevInput) => ({
      ...prevInput,
      customer_address: [
        ...prevInput.customer_address,
        {
          address: '',
          p_location: '',
          del_zone: '',
          delivery_zones: '',
          resident_name: '',
          resident_contact: '',
          resident_name_two: '',
          resident_contact_two: '',
          location_note: '',
        },
      ],
    }))
  }

  const handleAddPhoneInput = (e) => {
    e.preventDefault()
    setXeroContact((prevInput) => ({
      ...prevInput,
      Phones: [
        ...prevInput.Phones,
        {
          PhoneType: '',
          PhoneNumber: '',
          PhoneAreaCode: '',
          PhoneCountryCode: '',
        },
      ],
    }))
  }

  const handleRemoveInput = (e, i) => {
    e.preventDefault()
    setXeroCustomer((prevInput) => {
      const list = { ...prevInput }
      list.customer_address.splice(i, 1)
      return list
    })
  }

  const handleRemoveInputPhone = (e, i) => {
    e.preventDefault()
    setXeroContact((prevInput) => {
      const updatedPhones = [...prevInput.Phones]
      updatedPhones.splice(i, 1)
      return { ...prevInput, Phones: updatedPhones }
    })
  }

  /* HANDLING ALL THE ON CHANGES BELOW */
  const handleChange = (e, index) => {
    e.persist()
    setXeroCustomer((prevInput) => {
      const list = { ...prevInput }
      list.customer_address[index][e.target.name] = e.target.value
      return list
    })

    setXeroCustomer((prevInput) => {
      const list = { ...prevInput }
      list.customer_address[index][e.target.name] = e.target.value
      return list
    })
  }

  const updateState = (setStateFunc, prevState, propertyName, value) => {
    setStateFunc({
      ...prevState,
      [propertyName]: value,
    })
  }

  const inputNameToStatePropertyMapping = {
    account_name: 'Name',
    account_contact_name: 'FirstName',
    code: 'CompanyNumber',
    terms: 'terms',
    email: 'EmailAddress',
  }

  const phoneInputNameMapping = {
    phone_type: 'PhoneType',
    phone_number: 'PhoneNumber',
  }

  const handleChangePhone = (e, index) => {
    e.persist()

    const statePropertyName = phoneInputNameMapping[e.target.name]

    if (!statePropertyName) {
      console.warn(`No mapping found for input name: ${e.target.name}`)
      return
    }

    setXeroContact((prevInput) => {
      const updatedPhones = [...prevInput.Phones]
      const updatedPhone = { ...updatedPhones[index] }

      updatedPhone[statePropertyName] = e.target.value
      updatedPhones[index] = updatedPhone

      return {
        ...prevInput,
        Phones: updatedPhones,
      }
    })
  }

  const handleInput = (e) => {
    e.persist()

    const statePropertyName = inputNameToStatePropertyMapping[e.target.name]

    if (!statePropertyName) {
      console.warn(`No mapping found for input name: ${e.target.name}`)
      return
    }

    updateState(setXeroContact, xeroContact, statePropertyName, e.target.value)
    updateState(setXeroCustomer, xeroCustomer, statePropertyName, e.target.value)
  }
  /* HANDLING ALL THE ON CHANGES BELOW */

  const Addresses = []
  const Phones = []

  xeroContact.Phones.forEach((phone) => {
    Phones.push({
      phone_type: phone.PhoneType,
      phone_number: phone.PhoneNumber,
      phone_area_code: phone.PhoneAreaCode,
      phone_country_code: phone.PhoneAreaCode,
    })
  })

  xeroCustomer?.customer_address.forEach((address) => {
    Addresses.push({
      AddressType: 'POBOX',
      AddressLine1: address?.address,
      City: address?.del_zone,
      PostalCode: 'true',
      AttentionTo: address?.resident_contact,
    })
  })

  const xeroData = {
    Contacts: [
      {
        Name: xeroCustomer?.Name,
        FirstName: xeroCustomer?.FirstName,
        LastName: '',
        CompanyNumber: xeroCustomer?.CompanyNumber,
        EmailAddress: xeroCustomer?.EmailAddress,
        Addresses: Addresses,
        Phones: xeroContact?.Phones,
      },
    ],
  }

  const customerData = {
    account_name: xeroCustomer?.Name,
    account_contact_name: xeroCustomer?.FirstName,
    code: xeroCustomer?.CompanyNumber,
    terms: xeroCustomer?.terms,
    customer_address: xeroCustomer?.customer_address,
    customer_phone: Phones,
    email: xeroCustomer?.EmailAddress,
    terms: xeroCustomer?.terms,
  }

  const xeroIfNotExistingCustomer = {
    contact_id: props.match.params.id,
    account_name: xeroContact?.Name,
    account_contact_name: xeroCustomer?.FirstName,
    code: xeroCustomer?.CompanyNumber,
    terms: xeroCustomer?.terms,
    customer_address: xeroCustomer?.customer_address,
    customer_phone: Phones,
    email: xeroCustomer?.EmailAddress,
    terms: xeroCustomer?.terms,
  }

  const xeroIfNotExistingContact = {
    Contacts: [
      {
        Name: xeroCustomer?.Name,
        FirstName: xeroCustomer?.FirstName,
        LastName: '',
        CompanyNumber: xeroCustomer?.CompanyNumber,
        EmailAddress: xeroCustomer?.EmailAddress,
        Addresses: Addresses,
        Phones: xeroContact?.Phones,
      },
    ],
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        let customerRes = {}
        if (isUpdateMode) {
          const customer_id = props.match.params.id
          customerRes = await axios.get(`api/edit_customers/${customer_id}`)
          if (customerRes.data.status === 200) {
            console.log(customerRes.data.customer)
            setXeroCustomer(customerRes.data.customer)
            setXeroContact(customerRes.data.xero)

            if (customerRes.data.customer === null) {
              setXeroCustomer(defaultXeroCustomer)
              //console.log(xeroCustomer)
            }
          } else {
            swal('Error', customerRes.data.message, 'error')
            history.push('/customers')
            return
          }
        }

        const termsRes = await axios.get('/api/customer_terms')
        if (termsRes.data.status === 200) {
          setTermList(termsRes.data.customer_terms)
        }

        const deliveryRes = await axios.get('/api/delivery_zones')
        if (deliveryRes.data.status === 200) {
          setDelList(deliveryRes.data.delivery_zones)
        }

        setLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error)
        setLoading(false)
      }
    }
    fetchData()
  }, [isUpdateMode, history, props.match.params.id])

  const submitForm = async (e) => {
    e.preventDefault()

    //console.log(xeroContact.Name)

    try {
      let response = {}
      let xeroResponse = {}
      //console.log(isUpdateMode)
      if (isUpdateMode) {
        const customer_id = props.match.params.id
        const id = xeroCustomer.id
        //console.log('Xero')
        try {
          if (id) {
            console.log(xeroCustomer)
            response = await axios.put(`/api/update_customers/${customer_id}`, xeroCustomer)
            //xeroResponse = await axios.put(`/api/update_xero_contact/${id}`, xeroContact)
          } else {
            response = await axios.post('/api/create_customer', xeroIfNotExistingCustomer)
            //xeroResponse = await axios.put(`/api/update_xero_contact/${id}`, xeroData)
          }
        } catch (error) {
          console.error('Error submitting form:', error)
        }
      } else {
        //console.log(xeroCustomer)
        response = await axios.post('/api/create_customer', xeroIfNotExistingCustomer)
        response = await axios.post('/api/create_xero_contact', xeroIfNotExistingContact)
      }

      if (response.data.status === 200) {
        swal('Success', response.data.message, 'success')
        history.push('/customers')
        setError([])
      } else if (response.data.status === 422) {
        setError(response.data.errors)
        //console.log(response.data.errors)
      } else if (response.data.status === 404) {
        swal('Error', response.data.message, 'error')
        history.push('/customers')
      }
    } catch (error) {
      console.error('Error submitting form:', error)
    }
  }

  if (loading) {
    return <Preloader />
  }

  return (
    <div className="content-wrapper">
      {/* Breadcrumb navigation */}
      <nav aria-label="breadcrumb p-3">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <NavLink to="/">Dashboard</NavLink>
          </li>
          <li className="breadcrumb-item">
            <NavLink to="/customers">Customers</NavLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page"></li>
        </ol>
      </nav>

      {/* Main title */}
      <h3 className="text-dark">{isUpdateMode ? 'Xero Contact Details' : 'Add New Customer'}</h3>

      <div className="content">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">Xero Contact</h5>
          </div>
          <div className="card-body">
            <form onSubmit={submitForm} id="product_form">
              <div className="card-body">
                <h4 className="mb-4">Xero Contact Details</h4>
                <div className="row">
                  {/* Customer Name */}
                  <div className="col-lg-3 mb-3">
                    <div className="form-group">
                      <label className="pb-2">
                        Customer Account Name
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="account_name"
                        onChange={handleInput}
                        value={xeroContact.Name}
                        placeholder="Account Name"
                        disabled
                      />
                      <span className="text-danger">{errorList.account_name}</span>
                    </div>
                  </div>

                  {/* Customer Name */}
                  <div className="col-lg-3 mb-3">
                    <div className="form-group">
                      <label className="pb-2">
                        Account Contact Name <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="account_contact_name"
                        onChange={handleInput}
                        value={xeroContact.FirstName}
                        placeholder="Account Contact Name"
                        disabled
                      />
                      <span className="text-danger">{errorList.account_contact_name}</span>
                    </div>
                  </div>

                  {/* Customer Code */}
                  <div className="col-lg-3 mb-3">
                    <div className="form-group">
                      <label className="pb-2">
                        Customer Code <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="code"
                        onChange={handleInput}
                        value={xeroContact.AccountNumber}
                        placeholder="Customer Code"
                        disabled
                      />
                      <span className="text-danger">{errorList.AccountNumber}</span>
                    </div>
                  </div>

                  {/* customer (email) */}
                  <div className="col-lg-3 mb-3">
                    <div className="form-group">
                      <label className="pb-2">Email</label>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        onChange={handleInput}
                        value={xeroContact.EmailAddress}
                        placeholder="Email"
                        disabled
                      />
                      <span className="text-danger">{errorList.email}</span>
                    </div>
                  </div>
                </div>

                {xeroContact.Phones.map((phone, i) => (
                  <>
                    <div className="row">
                      {/* Phone (home) */}
                      <div className="col-lg-3 mb-3">
                        <div className="form-group">
                          <label className="pb-2">Phone Type </label>
                          <CFormSelect
                            aria-label="Default select example"
                            name="phone_type"
                            onChange={(e) => handleChangePhone(e, i)}
                            value={phone.PhoneType}
                            disabled
                          >
                            <option value={'DEFAULT'} key={phone.id}>
                              Default
                            </option>
                            <option value={'FAX'} key={phone.id}>
                              FAX
                            </option>
                            <option value={'MOBILE'} key={phone.id}>
                              MOBILE
                            </option>
                            <option value={'DDI'} key={phone.id}>
                              DDI
                            </option>
                          </CFormSelect>

                          <span className="text-danger">
                            {errorList['customer_phone.' + i + '.phone_type']}
                          </span>
                        </div>
                      </div>

                      {/* Phone (home) */}
                      <div className="col-lg-3 mb-3">
                        <div className="form-group">
                          <label className="pb-2">Phone Number</label>
                          <input
                            className="form-control"
                            type="text"
                            name="phone_number"
                            onChange={(e) => handleChangePhone(e, i)}
                            value={phone.PhoneNumber}
                            placeholder="Number"
                            disabled
                          />
                          <span className="text-danger">
                            {errorList['customer_phone.' + i + '.phone_number']}
                          </span>
                        </div>
                      </div>

                      {/*  {xeroContact.Phones.length !== 1 && (
                        <div className="col-lg-1 mb-2 mt-4 pt-2">
                          <button
                            className="btn btn-danger"
                            onClick={(e) => handleRemoveInputPhone(e, i)}
                          >
                            Remove
                          </button>
                        </div>
                      )} */}
                    </div>
                  </>
                ))}

                <hr></hr>
                <h4 className="mb-4">Xero Contact Addresses</h4>
                {xeroContact?.Addresses.map((items, i) => (
                  <div key={i}>
                    <div className="row">
                      <div className="col-lg-3 mb-3">
                        <div className="form-group">
                          <label className="pb-2">Address Line 1</label>
                          <input
                            className="form-control"
                            type="text"
                            name="address"
                            value={items?.AddressLine1 ? items.AddressLine1 : 'N/A'}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-lg-3 mb-3">
                        <div className="form-group">
                          <label className="pb-2">Address Type</label>
                          <input
                            className="form-control"
                            type="text"
                            name="address"
                            value={items.AddressType ? items.AddressType : 'N/A'}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-lg-3 mb-3">
                        <div className="form-group">
                          <label className="pb-2">Attention To</label>
                          <input
                            className="form-control"
                            type="text"
                            name="address"
                            value={items.AttentionTo ? items.AttentionTo : 'N/A'}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-lg-3 mb-3">
                        <div className="form-group">
                          <label className="pb-2">City</label>
                          <input
                            className="form-control"
                            type="text"
                            name="address"
                            value={items.City ? items.City : 'N/A'}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-lg-3 mb-3">
                        <div className="form-group">
                          <label className="pb-2">Country</label>
                          <input
                            className="form-control"
                            type="text"
                            name="address"
                            value={items.Country ? items.Country : 'N/A'}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col-lg-3 mb-3">
                        <div className="form-group">
                          <label className="pb-2">Region</label>
                          <input
                            className="form-control"
                            type="text"
                            name="address"
                            value={items.Region ? items.Region : 'N/A'}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                  </div>
                ))}

                <h4 className="mb-4">Customer Addresses</h4>

                {/* Terms */}
                <div className="col-lg-3 mb-3">
                  <div className="form-group">
                    <label className="pb-2">
                      Terms <span className="text-danger">*</span>
                    </label>
                    <CFormSelect
                      aria-label="Default select example"
                      name="terms"
                      onChange={handleInput}
                      value={xeroCustomer?.terms}
                    >
                      <option value={0}>-- select --</option>
                      {termList.map((item) => {
                        return (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        )
                      })}
                    </CFormSelect>
                    <span className="text-danger">{errorList.terms}</span>
                  </div>
                </div>

                {xeroCustomer?.customer_address.map((items, i) => (
                  <div key={i}>
                    <div className="row mt-3">
                      <div className="col-lg-4 mb-3">
                        <div className="form-group">
                          <label className="pb-2">
                            Delivery Address <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="address"
                            onChange={(e) => handleChange(e, i)}
                            value={items.address}
                            placeholder="Delivery Address"
                          />

                          <span className="text-danger">
                            {errorList['customer_address.' + i + '.address']}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <div className="form-group">
                          <label className="pb-2">
                            Delivery Zone <span className="text-danger">*</span>
                          </label>
                          <CFormSelect
                            aria-label="Default select example"
                            name="del_zone"
                            onChange={(e) => handleChange(e, i)}
                            value={items.del_zone}
                          >
                            <option value={0}>-- select --</option>
                            {delList.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              )
                            })}
                          </CFormSelect>

                          <span className="text-danger">
                            {errorList['customer_address.' + i + '.del_zone']}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label className="pb-2">Address Note</label>
                          <input
                            className="form-control"
                            type="text"
                            name="location_note"
                            onChange={(e) => handleChange(e, i)}
                            value={items.location_note}
                            placeholder="Address Note"
                          />

                          <span className="text-danger">
                            {errorList['address_list.' + i + '.location_note']}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 mb-3">
                          <div className="form-group">
                            <label className="pb-2">Address Contact Name</label>
                            <input
                              className="form-control"
                              type="text"
                              name="resident_name"
                              onChange={(e) => handleChange(e, i)}
                              value={items.resident_name}
                              placeholder="Address Contact Name"
                            />

                            <span className="text-danger">
                              {errorList['address_list.' + i + '.resident_name']}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <div className="form-group">
                            <label className="pb-2">Address Contact Number</label>
                            <input
                              className="form-control"
                              type="text"
                              name="resident_contact"
                              onChange={(e) => handleChange(e, i)}
                              value={items.resident_contact}
                              placeholder="Address Contact Number"
                            />

                            <span className="text-danger">
                              {errorList['address_list.' + i + '.resident_contact']}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 mb-3">
                          <div className="form-group">
                            <label className="pb-2">Secondary Address Contact Name</label>
                            <input
                              className="form-control"
                              type="text"
                              name="resident_name_two"
                              onChange={(e) => handleChange(e, i)}
                              value={items.resident_name_two}
                              placeholder="Address Contact Name"
                            />

                            <span className="text-danger">
                              {errorList['address_list.' + i + '.resident_name_two']}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <div className="form-group">
                            <label className="pb-2">Secondary Address Contact Number</label>
                            <input
                              className="form-control"
                              type="text"
                              name="resident_contact_two"
                              onChange={(e) => handleChange(e, i)}
                              value={items.resident_contact_two}
                              placeholder="Address Contact Number"
                            />

                            <span className="text-danger">
                              {errorList['address_list.' + i + '.resident_contact_two']}
                            </span>
                          </div>
                        </div>
                      </div>
                      {xeroCustomer.customer_address.length !== 1 && (
                        <div className="col-lg-1 pt-2">
                          <button
                            className="btn btn-danger"
                            onClick={(e) => handleRemoveInput(e, i)}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}

                {xeroCustomer.customer_address && (
                  <div className="col-lg-2 mb-2 pt-2">
                    <button className="btn btn-success" onClick={handleAddInput}>
                      Add Address
                    </button>
                  </div>
                )}
                <hr></hr>

                {/* <pre>{JSON.stringify(inputList, null, 2)}</pre> */}
              </div>
              <div className="mx-3">
                <button type="submit" className="btn btn-primary">
                  {isUpdateMode ? 'Update' : 'Save'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Create
