import React, { useState, useEffect } from 'react'
import { useHistory, NavLink, useParams } from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert'
import Preloader from 'src/views/preloader/Preloader'

//services
import { getCrops, getCropSingle, createCrops, updateCrops, deleteCrops } from 'src/services'

//components
import { DataTableItem, UserRoute, BreadCrumb, Card } from 'src/components'

function CreateUpdate() {
  const history = useHistory()

  let { id } = useParams()
  const isUpdating = id !== undefined
  const [loading, setLoading] = useState(isUpdating)
  const [cropList, setCropList] = useState({
    id: '',
    name: '',
    xero_code: '',
    error_list: [],
  })

  const handleInput = (e) => {
    e.persist()
    setCropList({ ...cropList, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (isUpdating) {
      getCropSingle(id)
        .then((res) => {
          if (res.data.status === 200) {
            setCropList(res.data.crop)
          } else {
            history.push('/crops')
          }
          setLoading(false)
        })
        .catch(() => {
          history.push('/crops')
          setLoading(false)
        })
    }
  }, [id, history, isUpdating])

  const submitForm = (e) => {
    e.preventDefault()
    const data = cropList
    //console.log(data)

    const submitAction = isUpdating ? () => updateCrops(id, data) : () => createCrops(data)

    submitAction()
      .then((res) => {
        if (res.data.status === 200) {
          swal('Success', res.data.message, 'success')
          history.push('/crops')
        } else if (res.data.status === 400 || res.data.status === 422) {
          setCropList({ ...cropList, error_list: res.data.errors || {} })
          //console.log(cropList)
        }
      })
      .catch((error) => {
        swal('Error', 'An error occurred', 'error')
      })
  }

  if (loading) {
    return <Preloader />
  }

  const title = 'Crops'
  const isanupdate = isUpdating ? 'Update' : 'Add'

  const breadcrumb = [
    {
      title: title,
      name: 'Add Crops',
      subtitle: isanupdate,
      subtitleroute: '/crops',
    },
  ]

  const cardValues = [
    {
      title: 'Crops',
      addorupdate: isanupdate,
    },
  ]

  return (
    <div className>
      <BreadCrumb data={breadcrumb} />

      <div className="content">
        <div className="card card-primary">
          <Card data={cardValues}>
            <form onSubmit={submitForm} id="product_form">
              <div className="card-body">
                <div className="row">
                  {/* Name */}
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label className="pb-2">
                        Crops Type <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        onChange={handleInput}
                        value={cropList.name}
                        placeholder="Crops Type"
                      />
                      <span className="text-danger">{cropList?.error_list?.name}</span>
                    </div>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary mt-3">
                {isUpdating ? 'Update' : 'Save'}
              </button>
            </form>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default CreateUpdate
